html,
body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	color: var(--text-color);
	font-size: 20px;
}

.h-overview {
	height: 700px;
}

header {
	min-height: 60px;
	background-color: var(--bg-primary);
}

.text-ortus {
	color: var(--ortus-green);
}

.btn-primary {
	background-color: var(--bg-btn-primary);
	border-color: var(--bg-btn-primary);
}
.btn-secondary {
	color: var(--ortus-green);
	background-color: var(--bg-btn-secondary);
	border-color: var(--bg-btn-secondary);
}

.btn:hover {
    color: #ffffff;
    background-color: var(--bg-btn-primary-hover);
    border-color: var(--bg-btn-primary-hover);
}

.bg-primary {
	background-color: var(--bg-primary) !important;
}

footer {
	background-color: var(--bg-primary);
}

.bg-copy-right {
	background-color: var(--bg-copy-right);
}

.navbar-light {
	background-color: var(--bg-primary);
}

.footer-text-color, footer > a {
	color: var(--text-footer-color) !important;
}

.fs-7 {
	font-size: 0.9rem;
}

.fs-8 {
	font-size: 0.8rem;
}

.nav-link {
	color: var(--link-color);
}

.source-card:hover{
	border-color: var(--ortus-green) !important;
	transition: 0.2s ease all;
	transform: scale(1.1);
}

.copy-to-clipboard-button:hover > span{
	color: #ffffff;
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
	.h-overview {
		height: 600px;
	}
}

.social-media-link, 
.nav-link {
	color: #ffffff !important;
}

.social-media-link:hover, 
.social-media-link:focus,
.social-media-link:active,
.nav-link:hover,
.nav-link:focus,
.nav-link:active
{
	color: var(--bg-btn-primary-hover) !important;
}